import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import {
  catchError,
  combineLatest,
  first,
  forkJoin,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

import { OrderService } from 'src/app/_services/order.service';
import { CartState } from './cart.state';
import { VenueState } from 'src/app/_shared/_ngxs/venue.state';
import { SessionState } from './authentication.state';
import { DineInState } from 'src/app/venue/dine-in/_ngxs/dine-in.state';
import { HideSpinner, ShowSpinner } from '../_components/spinner/spinner.state';
import { OrderDataState } from './order-data.state';
import {
  CreateDineInOrder,
  CancelOrder,
  ChangeOrderIsFavorite,
  GetOrderPaymentDetails,
  GetItemsSuggestions,
  GetOrderDetails,
  UpdateDineInOrder,
  CreateNotDineInOrder,
  GetUpdatedOrderDetails,
  CreateGiftCards,
  CreateOneGiftCard,
  Reorder,
  SetApplePay,
  SetGooglePay,
  CancelExistingOrder,
  SetIsOpenTab,
  CloseOpenTab,
  UpdateOpenTab,
  SaveUpdatedOrderDetails,
  FinishReorderItems,
  ClearPurchasedGiftCards,
} from './order.actions';
import {
  ClearHistory,
  OpenCartDialog,
  OpenConfirmationDialog,
  OpenDialog,
} from './dialog.actions';
import { SetDineInOrderId } from 'src/app/venue/dine-in/_ngxs/dine-in.actions';
import {
  AddToCart,
  ClearAddressInDeliveryData,
  ClearCart,
  ClearGiftCards,
  CreateDeliveryQuote,
  RemoveItemFromCart,
  SavePaymentCard,
} from './cart.actions';
import { GetProfileOrders } from './profile.actions';
import { GetShadowUserToken, SaveUserName } from './authentication.actions';

import { MessagePopupComponent } from '../_components/message-popup/message-popup.component';

import { OrderType, OrderDomain, DeliveryMethod } from './../_enums/order.enum';
import { OrderItemStatus } from '../_enums/order-item-status.enum';
import { MatDialogId } from '../_enums/mat-dialog-id.enum';
import { OrderItemType } from '../_enums/order-item-type.enum';
import { PaymentMethods } from '../_enums/payment-methods.enum';
import { TaxRules } from '../_enums/item.enum';
import { SessionStorageKeys } from '../_enums/session-storage-keys.enum';
import { LocaleStorageKeys } from '../_enums/local-storage-keys.enum';

import { Phone } from '../_models/common.interface';
import {
  BundleItemConsumerRequest,
  CustomModifiersSelectionRequest,
  CWCreateOrderRequest,
  DineInOrderIDResponse,
  GenericOrderResponse,
  ModifierSelectionRequest,
  OrderItemConsumerRequest,
  OrderItemRequest,
  OrderSuccessItem,
} from '../_interfaces/order.model';
import {
  Item,
  ItemDetails,
  Modifier,
  SelectedModifier,
  StateItem,
  Tips,
} from './../_interfaces/item.model';
import {
  GiftCardPaymentPayload,
  CreateGiftCardPayload,
  OrderPaymentDetails,
} from '../_interfaces/payment.model';
import { ItemsSuggestionsResponse } from '../_models/items-suggestions-response.model';
import { GenericCheck } from '../_interfaces/check-calculation.model';
import { Paginator } from '../_models/paginator.model';
import { Card } from 'src/app/profile/_interfaces/payment.model';
import { GiftCard } from '../_interfaces/gift-card.model';

import { getNow, getNPeriodFromNow } from '../_constants/date';
import { copy } from '../_utils/common';
import {
  DEFAULT_SUGGESTIONS_PAGE_SIZE,
  DEFAULT_SUGGESTIONS_PERIOD_NUMBER,
  DEFAULT_SUGGESTION_PERIOD,
} from '../_constants/items-suggestsions.constants';
import { CARD_NOT_PRESENT_PAYMENT } from '../_constants/card.constants';

import { getOrderSuccessItemsFromOrderDetails } from '../_utils/order';
import { PaymentService } from 'src/app/_services/payment.service';
import { CatalogService } from 'src/app/_services/catalog.service';
import { NotificationService } from '../_services/notification.service';

import { GuestUserData } from 'src/app/_shared/_interfaces/session.model';
import { SessionStorageEngine } from 'src/app/_services/session-storage.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { getFilteredCartItems } from '../_utils/menus';
import { FilteredMenuState } from './filtered-menu.state';

interface OrderStateModel {
  orderId: string;
  dineInOrderID: string;
  orderDetails: GenericOrderResponse | null;
  paymentDetails: OrderPaymentDetails[];
  rebuyItemsSuggestions: ItemDetails[];
  suggestionsPaginator: Paginator | null;
  tips: Tips | null;
  applePay: any | null;
  googlePay: any | null;
  status: string | null;
  isOpenTab: boolean;
  purchasedGiftCards: GiftCard[];
  payloadForGiftCards: any[];
}

const defaults: OrderStateModel = {
  orderId: '',
  dineInOrderID: '',
  orderDetails: null,
  paymentDetails: [],
  rebuyItemsSuggestions: [],
  suggestionsPaginator: null,
  tips: null,
  applePay: null,
  googlePay: null,
  status: null,
  isOpenTab: false,
  purchasedGiftCards: [],
  payloadForGiftCards: [],
};

@State<OrderStateModel>({
  name: 'order',
  defaults,
})
@Injectable()
export class OrderState {
  constructor(
    private readonly orderService: OrderService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly ngZone: NgZone,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly paymentService: PaymentService,
    private readonly catalogService: CatalogService,
    private readonly notificationService: NotificationService,
    private readonly sessionStorage: SessionStorageEngine,
    private localStorage: LocalStorageService
  ) {}

  private showSuccessMessage(message: string): void {
    this.notificationService.showSuccess(message);
  }

  @Selector()
  static orderId({ orderId }: OrderStateModel): string | null {
    return orderId;
  }

  @Selector()
  static purchasedGiftCards({
    purchasedGiftCards,
  }: OrderStateModel): GiftCard[] {
    return purchasedGiftCards;
  }

  @Selector()
  static payloadForGiftCards({ payloadForGiftCards }: OrderStateModel): any[] {
    return payloadForGiftCards;
  }

  @Selector()
  static dineInOrderID({ dineInOrderID }: OrderStateModel): string | null {
    return dineInOrderID;
  }

  @Selector()
  static orderDetails({
    orderDetails,
  }: OrderStateModel): GenericOrderResponse | null {
    return orderDetails;
  }

  @Selector()
  static paymentDetails({
    paymentDetails,
  }: OrderStateModel): OrderPaymentDetails[] {
    return paymentDetails;
  }

  @Selector()
  static tips({ tips }: OrderStateModel): Tips | null {
    return tips;
  }

  @Selector()
  static rebuyItemsSuggestions({
    rebuyItemsSuggestions,
  }: OrderStateModel): ItemDetails[] {
    return rebuyItemsSuggestions;
  }

  @Selector()
  static orderSuccessItems({
    orderDetails,
    paymentDetails,
  }: OrderStateModel): OrderSuccessItem[] {
    if (!paymentDetails?.length || !orderDetails) {
      return [];
    }

    const orderSuccessItems = getOrderSuccessItemsFromOrderDetails(
      orderDetails,
      paymentDetails[0].checkId
    );
    return orderSuccessItems;
  }

  @Selector()
  static check({
    orderDetails,
    paymentDetails,
  }: OrderStateModel): GenericCheck | undefined {
    if (!paymentDetails?.length || !orderDetails) {
      return undefined;
    }

    const checkIds: string[] = paymentDetails.map(({ checkId }) => checkId);

    return (orderDetails?.checks || []).find(
      ({ checkId }) => checkId && checkIds.includes(checkId)
    );
  }

  @Selector()
  static applePay({ applePay }: OrderStateModel): any | null {
    return applePay;
  }

  @Selector()
  static googlePay({ googlePay }: OrderStateModel): any | null {
    return googlePay;
  }

  @Selector()
  static status({ status }: OrderStateModel): string | null {
    return status;
  }

  @Selector()
  static isOpenTab({ isOpenTab }: OrderStateModel): boolean {
    return isOpenTab;
  }

  @Action(CreateNotDineInOrder)
  createNotDineInOrder(context: StateContext<OrderStateModel>) {
    context.dispatch(new ShowSpinner());

    const tips: Tips = this.store.selectSnapshot(CartState.tips);
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const deliveryData = this.store.selectSnapshot(OrderDataState.orderData)!;
    const { orderType } = deliveryData;
    const order: CWCreateOrderRequest = this.getOrderDetailsForRequestWithItems(
      String(orderType)
    );

    orderType === DeliveryMethod.Curbside &&
      (order.carInfo = deliveryData.data.carInfo);

    if (orderType === DeliveryMethod.Delivery) {
      order.address = deliveryData.data.address;

      !isLoggedIn &&
        (order.phoneNumber = this.store.selectSnapshot(
          CartState.guestUserPhoneNumber
        ));
    }

    if (isLoggedIn) {
      return this.getCreateOrderRequestObservable(order).pipe(
        tap(orderDetails => {
          context.patchState({
            orderId: orderDetails.orderId,
            orderDetails,
            tips: tips,
            status: 'success',
          });
          context.dispatch([
            new ClearCart(),
            new HideSpinner(),
            new ClearAddressInDeliveryData(),
          ]);
          this.sessionStorage.removeItem(
            SessionStorageKeys.isReorderingDisabled
          );
          this.goToOrderSuccess();
        }),

        catchError(() => {
          deliveryData.orderType === DeliveryMethod.Delivery &&
            this.store.dispatch(new CreateDeliveryQuote());

          return of();
        })
      );
    } else {
      const updatedOrder = { ...order };
      const phoneNumber: string = this.store.selectSnapshot(
        OrderDataState.orderPhoneAsString
      );
      !isLoggedIn && phoneNumber && (updatedOrder.phoneNumber = phoneNumber);

      return this.createShadowSessionAndOrder(context, updatedOrder, tips).pipe(
        catchError(() => {
          deliveryData.orderType === DeliveryMethod.Delivery &&
            this.store.dispatch(new CreateDeliveryQuote());

          return of();
        })
      );
    }
  }

  @Action(CreateDineInOrder)
  createDineInOrder(context: StateContext<OrderStateModel>) {
    context.dispatch(new ShowSpinner());

    const orderId = this.store.selectSnapshot(DineInState.orderId);
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);

    if (!!orderId) {
      if (isLoggedIn) {
        const order = this.getOrderDetailsForRequestWithItems(OrderType.DineIn);
        order.openTab = context.getState().isOpenTab;

        return this.updateOrAddToOrder(context, orderId, order);
      } else {
        return this.createShadowAndUpdateOrder(context, orderId);
      }
    } else {
      const tips: Tips = this.store.selectSnapshot(CartState.tips);
      const order = this.getOrderDetailsForRequestWithItems(OrderType.DineIn);
      order.openTab = context.getState().isOpenTab;
      if (isLoggedIn) {
        return this.getCreateOrderRequestObservable(order).pipe(
          tap(order => {
            context.patchState({
              orderId: order.orderId,
              dineInOrderID: order.orderId,
              orderDetails: order,
              tips: tips,
              status: 'success',
            });
            context.dispatch([
              new SetDineInOrderId(order.orderId),
              new ClearCart(),
              new HideSpinner(),
              new ClearAddressInDeliveryData(),
            ]);
            this.goToOrderSuccess(true);
          })
        );
      } else {
        return this.createShadowSessionAndOrder(context, order, tips);
      }
    }
  }

  @Action(ClearPurchasedGiftCards)
  clearPurchasedGiftCards({ patchState }: StateContext<OrderStateModel>) {
    patchState({ purchasedGiftCards: [], payloadForGiftCards: [] });
  }

  @Action(CreateGiftCards)
  createGiftCards({
    dispatch,
  }: StateContext<OrderStateModel>): Observable<void> | void {
    const isTokenExists = this.store.selectSnapshot(SessionState.isLoggedIn);
    const giftCards = (
      this.store.selectSnapshot(CartState.giftCards) || []
    ).filter(({ selectedInCart }) => selectedInCart);

    if (isTokenExists) {
      if (giftCards?.length) {
        return dispatch(new CreateOneGiftCard(giftCards[0]));
      }
    } else {
      const guestUserData: GuestUserData = copy(
        this.store.selectSnapshot(CartState.guestUserData)
      );
      const { name, shadowUserUsername, card, isCardValid } = guestUserData;

      return this.store
        .dispatch([
          new SaveUserName(name),
          new GetShadowUserToken(shadowUserUsername),
        ])
        .pipe(
          switchMap(() => {
            return isCardValid
              ? this.store.dispatch(new SavePaymentCard(card))
              : of(true);
          }),
          tap(() => {
            if (giftCards?.length) {
              dispatch(new CreateOneGiftCard(giftCards[0]));
            }
          })
        );
    }
  }

  @Action(CreateOneGiftCard)
  createOneGiftCart(
    { dispatch, getState, patchState }: StateContext<OrderStateModel>,
    { giftCard }: CreateOneGiftCard
  ) {
    dispatch(new ShowSpinner());

    const purchasedGiftCards = getState().purchasedGiftCards;
    const payloadForGiftCards = getState().payloadForGiftCards;

    const selectedGiftCard = this.store.selectSnapshot(
      CartState.selectedGiftCard
    );
    const selectedPaymentCard = this.store.selectSnapshot(
      CartState.selectedPaymentCard
    );

    const type: PaymentMethods = this.store.selectSnapshot(
      CartState.paymentMethod
    );

    const order = !!selectedGiftCard
      ? this.getOrderDetailsForGiftCardPayment(giftCard.amount ?? 0)
      : !!selectedPaymentCard
      ? this.getOrderDetailsForRequestWithGiftCard(giftCard.amount ?? 0)
      : type === PaymentMethods.apple_pay
      ? this.getOrderDetailsForGiftCardApplePayment(giftCard.amount ?? 0)
      : type === PaymentMethods.google_pay
      ? this.getOrderDetailsForGiftCardGooglePayment(giftCard.amount ?? 0)
      : null;

    return this.paymentService.createGiftCardPayment(type, order).pipe(
      switchMap(paymentResponse => {
        const payload = this.getOrderDetailsForGiftCardRequest(
          paymentResponse.id
        );

        patchState({
          payloadForGiftCards: [...payloadForGiftCards, paymentResponse],
        });

        return this.paymentService.createGiftCard(payload).pipe(
          tap({
            next: ({ giftCardNumber }) => {
              patchState({
                purchasedGiftCards: [
                  ...purchasedGiftCards,
                  { ...giftCard, giftCardNumber },
                ],
              });

              dispatch([
                new RemoveItemFromCart(giftCard, OrderItemType.gift_card),
                new HideSpinner(),
              ]);
            },
            complete: () => {
              const giftCardsNumber = this.store
                .selectSnapshot(CartState.giftCards)
                ?.filter(({ selectedInCart }) => selectedInCart).length;

              if (giftCardsNumber > 0) {
                dispatch(new CreateGiftCards());
              } else {
                this.ngZone.run(() => {
                  this.dialog.closeAll();
                  dispatch([new ClearHistory(), new HideSpinner()]);
                  this.goToGiftSuccess();
                });
              }
            },
          })
        );
      })
    );
  }

  @Action(Reorder)
  reorder({ dispatch }: StateContext<OrderStateModel>, { order }: Reorder) {
    const existingGiftCardsInCart: GiftCard[] = this.store.selectSnapshot(
      CartState.giftCards
    );

    if (!existingGiftCardsInCart.length) {
      dispatch(new FinishReorderItems(order));
    } else {
      dispatch(
        new OpenConfirmationDialog(
          this.translateService.instant('CART.confirm_question')
        )
      )
        .pipe(first())
        .subscribe(() => {
          this.dialog
            .getDialogById(MatDialogId.confirmation)
            ?.afterClosed()
            .pipe(first((confirmed: boolean) => confirmed))
            .subscribe(() => {
              dispatch([new ClearGiftCards(), new FinishReorderItems(order)]);
            });
        });
    }
  }

  @Action(FinishReorderItems)
  finishReorderItems(
    { dispatch }: StateContext<OrderStateModel>,
    { order }: Reorder
  ) {
    let isAvailableToReorder: boolean = true;
    const availableItems: Item[] = order.items.filter(
      (item: Item) => item.isAvailable
    );
    const notAvailableItems: Item[] = order.items.filter(
      (item: Item) => !item.isAvailable
    );

    dispatch(new ShowSpinner());

    notAvailableItems.forEach((notAvailableItem: Item) =>
      this.notificationService.showError(
        'item_is_not_available_now',
        notAvailableItem.name
      )
    );

    return forkJoin([
      forkJoin(
        availableItems.map(item =>
          this.catalogService.getItemDetails(item.itemId)
        )
      ),
      forkJoin(
        availableItems.map(item =>
          this.catalogService.getItemModifiers(item.itemId)
        )
      ),
    ]).pipe(
      tap({
        next: ([items, modifiers]) => {
          const itemsToCart: StateItem[] = [];

          for (let i = 0; i < items.length; i++) {
            const menu = this.store
              .selectSnapshot(FilteredMenuState.menus)
              .find(menu => menu.menuId === availableItems[i].metadata.menuId);
            const section = menu?.sections.find(
              section =>
                section.sectionName === availableItems[i].menuSectionName
            );
            const sectionItem = section?.items.find(
              item => item.itemId === availableItems[i].itemId
            );
            const itemModifiers = this.getItemModifiers(
              availableItems,
              i,
              modifiers
            );

            const size = items[i].sizesAndPrices.find(
              size => size.id === availableItems[i].metadata.sizeId
            );

            if (!sectionItem) {
              isAvailableToReorder = false;
            }

            if (isAvailableToReorder) {
              const stateItem = {
                item: sectionItem,
                menuId: availableItems[i].metadata.menuId,
                menuSectionId: availableItems[i].metadata.sectionId,
                modifiers: itemModifiers,
                specialRequest:
                  availableItems[i].metadata.customModifiersSelections?.[0]
                    .description || null,
                size,
                taxRule: availableItems[i].taxRule as TaxRules,
                quantity: 1,
                cartItemId: uuidv4(),
              };

              const sameItemIndex = itemsToCart.findIndex(itemFromCart =>
                this.areSameItems(itemFromCart, stateItem as StateItem)
              );

              if (~sameItemIndex) {
                itemsToCart[sameItemIndex].quantity += 1;
              } else {
                itemsToCart.push(stateItem as StateItem);
              }
            }
          }

          for (let stateItem of itemsToCart) {
            this.store.dispatch(
              new AddToCart({
                ...stateItem,
                cartItemId: stateItem.cartItemId || uuidv4(),
              })
            );
          }

          if (isAvailableToReorder) {
            this.store.dispatch(new OpenCartDialog());
          } else {
            this.store.dispatch(new HideSpinner());
            this.notificationService.showError(
              'ERRORS.menu_or_item_is_not_available_now'
            );
          }

          this.dialog.getDialogById(MatDialogId.profile_order_details)?.close();
        },
        complete: () => dispatch(new HideSpinner()),
      })
    );
  }

  private areSameItems(firstItem: StateItem, secondItem: StateItem): boolean {
    return (
      firstItem.item.itemId === secondItem.item.itemId &&
      isEqual(firstItem.modifiers, secondItem.modifiers) &&
      isEqual(firstItem.size, secondItem.size) &&
      firstItem.specialRequest === secondItem.specialRequest
    );
  }

  private getItemModifiers(
    availableItems: Item[],
    i: number,
    modifiers: Modifier[][]
  ): SelectedModifier[] {
    const result: SelectedModifier[] = [];

    if (!availableItems[i].metadata.modifierSelections) {
      return result;
    }

    for (let modifierSelection of availableItems[i].metadata
      .modifierSelections) {
      const originalModifier = modifiers[i].find(
        modifier => modifier.id === modifierSelection.modifierId
      );

      if (modifierSelection.modifierOptionIds && originalModifier) {
        for (let selectedOption of modifierSelection.modifierOptionIds) {
          const originalOption = originalModifier.options.find(
            option => option.id === selectedOption
          );

          if (originalOption) {
            result.push({
              mandatory: originalModifier.itemModifier.mandatory,
              modifierId: originalModifier.id,
              modifierTitle: originalModifier.title,
              selectedOptionId: originalOption.id,
              selectedOptionTitle: originalOption.title,
            });
          }
        }
      }
    }

    return result;
  }

  private getCreateOrderRequestObservable(order: CWCreateOrderRequest) {
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);

    return isLoggedIn
      ? this.orderService.createProfileOrder(order)
      : this.orderService.createOrder(order);
  }

  private createShadowSessionAndOrder(
    context: StateContext<OrderStateModel>,
    order: CWCreateOrderRequest,
    tips: Tips
  ) {
    const guestUserData: GuestUserData = copy(
      this.store.selectSnapshot(CartState.guestUserData)
    );

    const isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const { name, shadowUserUsername, card, isCardValid } = guestUserData;
    return this.store
      .dispatch(
        isShadowUser
          ? []
          : [new SaveUserName(name), new GetShadowUserToken(shadowUserUsername)]
      )
      .pipe(
        switchMap(() => {
          return isCardValid
            ? this.store.dispatch(new SavePaymentCard(card))
            : of(true);
        }),
        switchMap(() => {
          const selectedPaymentMethod: Card | null = this.store.selectSnapshot(
            CartState.selectedPaymentCard
          );
          const updatedOrder: CWCreateOrderRequest = {
            ...order,
            cctoken: selectedPaymentMethod?.id,
          };
          const phoneNumber: string = this.store.selectSnapshot(
            OrderDataState.orderPhoneAsString
          );

          !isLoggedIn &&
            phoneNumber &&
            (updatedOrder.phoneNumber = phoneNumber);
          context.dispatch(new ShowSpinner());

          return this.getCreateOrderRequestObservable(updatedOrder).pipe(
            tap(orderDetails => {
              context.patchState({
                orderId: orderDetails.orderId,
                orderDetails,
                tips: tips,
                status: 'success',
              });
              context.dispatch([
                new ClearCart(),
                new HideSpinner(),
                new ClearAddressInDeliveryData(),
              ]);

              this.goToOrderSuccess();
            })
          );
        })
      );
  }

  private updateOrAddToOrder(
    context: StateContext<OrderStateModel>,
    orderId: string,
    order: CWCreateOrderRequest
  ) {
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    return (
      isLoggedIn
        ? this.orderService.createLoggedGuestOrder(orderId, order)
        : this.orderService.addDiner(orderId, order)
    ).pipe(
      tap({
        next: ({ dineInOrderID, paymentIds }: DineInOrderIDResponse) => {
          context.patchState({ dineInOrderID });
          context.dispatch([
            new GetOrderDetails(dineInOrderID, paymentIds),
            new ClearCart(),
            new ClearAddressInDeliveryData(),
          ]);
        },
        error: (err: any) => {
          context.dispatch(new UpdateDineInOrder(orderId, order));
        },
      })
    );
  }

  private createShadowAndUpdateOrder(
    context: StateContext<OrderStateModel>,
    orderId: string
  ) {
    const guestUserData: GuestUserData = copy(
      this.store.selectSnapshot(CartState.guestUserData)
    );
    const isShadowUser = this.store.selectSnapshot(SessionState.isShadowUser);
    const { name, shadowUserUsername, card, isCardValid } = guestUserData;
    return this.store
      .dispatch(
        isShadowUser
          ? []
          : [new SaveUserName(name), new GetShadowUserToken(shadowUserUsername)]
      )
      .pipe(
        switchMap(() => {
          return isCardValid
            ? this.store.dispatch(new SavePaymentCard(card))
            : of(true);
        }),
        switchMap(() => {
          const order = this.getOrderDetailsForRequestWithItems(
            OrderType.DineIn
          );
          const phoneNumber: string = this.store.selectSnapshot(
            OrderDataState.orderPhoneAsString
          );

          context.dispatch(new ShowSpinner());
          order.openTab = context.getState().isOpenTab;
          phoneNumber && (order.phoneNumber = phoneNumber);

          return this.updateOrAddToOrder(context, orderId, order);
        })
      );
  }

  private getOrderDetailsForRequestWithGiftCard(
    amount: number
  ): GiftCardPaymentPayload {
    let cardInfo = cloneDeep(
      this.store.selectSnapshot(CartState.selectedPaymentCard)
    ) as Card;
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);

    cardInfo.cardNumber =
      cardInfo.cardNumber || cardInfo?.redactedCardNumber || '';

    return {
      venueId,
      serviceType: 'gift-card',
      productSource: 'customWebsite',
      cardInfo: {
        cardBrand: cardInfo.cardBrand,
        cardHolderName: cardInfo.cardholderName,
        cvv: cardInfo.cvv,
        redactedCardNumber: cardInfo.cvv,
        tokenId: cardInfo.id || '',
        type: 'credit',
      },
      amount,
    };
  }

  private getOrderDetailsForGiftCardApplePayment(amount: number): any {
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);

    const applePayInfo = cloneDeep(
      this.store.selectSnapshot(OrderState.applePay)
    );

    return {
      amount: amount,
      applePay: applePayInfo,
      serviceType: 'gift-card',
      productSource: 'customWebsite',
      venueId: venueId,
    };
  }

  private getOrderDetailsForGiftCardGooglePayment(amount: number): any {
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);

    const googlePayInfo = cloneDeep(
      this.store.selectSnapshot(OrderState.googlePay)
    );

    return {
      amount: amount,
      googlePay: googlePayInfo,
      serviceType: 'gift-card',
      productSource: 'customWebsite',
      venueId: venueId,
    };
  }

  private getOrderDetailsForGiftCardPayment(
    amount: number
  ): GiftCardPaymentPayload {
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);

    const giftCardPaymentInfo = cloneDeep(
      this.store.selectSnapshot(CartState.selectedGiftCard)
    ) as GiftCard;

    return {
      amount: amount,
      giftCardNumber: giftCardPaymentInfo.giftCardNumber,
      serviceType: 'gift-card',
      productSource: 'customWebsite',
      venueId: venueId,
    };
  }

  private getOrderDetailsForGiftCardRequest(
    paymenId: string
  ): CreateGiftCardPayload {
    const giftCard = this.store.selectSnapshot(CartState.giftCards)?.[0];
    const guestEmail = this.store.selectSnapshot(SessionState.email);
    const venueId = this.store.selectSnapshot(VenueState.venueId);
    const phone = giftCard?.recipientPhone as Phone;
    const phoneNumber = phone?.phoneNumber
      ? `${phone?.countryCode}${phone?.phoneNumber}`
      : '';

    const localtz = moment.tz.guess(); // user's timezone
    const deliveryDate = giftCard.deliveryDate
      ? moment.tz(giftCard.deliveryDate, localtz).format()
      : '';

    return {
      recipientName: giftCard.recipientName ?? '',
      recipientEmail: giftCard.recipientEmail ?? '',
      recipientPhone: phoneNumber ?? '',
      message: giftCard.message ?? '',
      deliveryDate: deliveryDate ?? '',
      senderName: giftCard.senderName ?? '',
      senderEmail: giftCard.senderEmail ?? guestEmail ?? '',
      purchasePaymentId: paymenId,
      venueId: venueId,
    };
  }

  private getOrderDetailsForRequestWithItems(
    orderType: string
  ): CWCreateOrderRequest {
    const card: Card | GiftCard = this.selectedPaymentMethod();
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);
    const scheduledTime: string | undefined = this.store.selectSnapshot(
      OrderDataState.scheduledTime
    );
    const completePaymentMethod: PaymentMethods | undefined =
      this.store.selectSnapshot(CartState.completePaymentMethod);
    const paymentMethod: PaymentMethods | undefined = this.store.selectSnapshot(
      CartState.paymentMethod
    );
    const selectedGiftCard = this.store.selectSnapshot(
      CartState.selectedGiftCard
    );
    const isGiftCardCoverTotal: boolean = this.store.selectSnapshot(
      CartState.isGiftCardCoverTotal
    );

    const orderDetailsForRequest: CWCreateOrderRequest = {
      venueId,
      name: this.store.selectSnapshot(SessionState.userName),
      cctoken: card.id!,
      tips: this.store.selectSnapshot(CartState.tipsInCents),
      paymentType:
        paymentMethod === PaymentMethods.card
          ? CARD_NOT_PRESENT_PAYMENT
          : paymentMethod,
      orderType,
      domain: OrderDomain.Restaurant,
      floorPlanSectionId: this.store.selectSnapshot(CartState.sectionId),
      floorPlanElementIds: [this.store.selectSnapshot(CartState.tableId)],
      orderItems: this.getOrderItemsRequestPayload(),
      quoteId: null,
      applePay: null,
      googlePay: null,
    };

    if (!!selectedGiftCard) {
      orderDetailsForRequest.paymentType = 'gift-card';
      orderDetailsForRequest.giftCard = selectedGiftCard.giftCardNumber;
    }

    if (
      orderType !== OrderType.DineIn &&
      orderType !== OrderType.Delivery &&
      scheduledTime
    ) {
      orderDetailsForRequest.scheduledTime = scheduledTime;
    }

    if (orderType === OrderType.Delivery) {
      orderDetailsForRequest.quoteId = this.store.selectSnapshot(
        CartState.quoteId
      );
    }

    if (
      completePaymentMethod === PaymentMethods.card &&
      paymentMethod === PaymentMethods.gift_card &&
      !isGiftCardCoverTotal
    ) {
      orderDetailsForRequest.paymentType = CARD_NOT_PRESENT_PAYMENT;
      if (!!selectedGiftCard) {
        orderDetailsForRequest.giftCardAmount = selectedGiftCard.currentBalance;
      }
    }

    if (paymentMethod === PaymentMethods.apple_pay) {
      orderDetailsForRequest.applePay = this.store.selectSnapshot(
        OrderState.applePay
      );
      if (!!selectedGiftCard) {
        orderDetailsForRequest.giftCardAmount = selectedGiftCard.currentBalance;
      }
    }

    if (paymentMethod === PaymentMethods.google_pay) {
      orderDetailsForRequest.googlePay = this.store.selectSnapshot(
        OrderState.googlePay
      );

      if (!!selectedGiftCard) {
        orderDetailsForRequest.giftCardAmount = selectedGiftCard.currentBalance;
      }
    }
    return orderDetailsForRequest;
  }

  private getOrderItemsRequestPayload(): OrderItemConsumerRequest[] {
    const cartItems = this.store.selectSnapshot(CartState.items);
    const orderType = this.store.selectSnapshot(OrderDataState.orderType);
    const selectedOrderPeriod = this.store.selectSnapshot(
      OrderDataState.selectedOrderPeriod
    );
    const menus = this.store.selectSnapshot(FilteredMenuState.menus);
    const stateItems: StateItem[] = copy(
      getFilteredCartItems(cartItems, orderType, selectedOrderPeriod, menus)
    );

    return stateItems.map(item => {
      const {
        menuId,
        menuSectionId,
        quantity,
        size: { id: sizeId },
        item: { itemId },
        modifiers,
        bundleItems,
      } = item;

      const itemModifiers: any = {};

      for (const { modifierId, selectedOptionId } of modifiers) {
        if (!itemModifiers[modifierId])
          itemModifiers[modifierId] = {
            modifierId: modifierId,
            modifierOptionIds: [selectedOptionId],
          };
        else {
          itemModifiers[modifierId].modifierOptionIds.push(selectedOptionId);
        }
      }

      const selectedBundleItems: BundleItemConsumerRequest[] = [];
      if (bundleItems?.length) {
        bundleItems.forEach(({ bundleItemId, bundleSectionId, modifiers }) => {
          const modifierSelections: ModifierSelectionRequest[] = [];
          modifiers?.forEach(
            ({
              modifierId,
              selectedOptionId,
            }: {
              modifierId: string;
              selectedOptionId: string;
            }) => {
              const existingItemIndex: number = modifierSelections.findIndex(
                ({ modifierId: selectedModifierId }) =>
                  selectedModifierId === modifierId
              );
              if (existingItemIndex > -1) {
                modifierSelections[existingItemIndex].modifierOptionIds?.push(
                  selectedOptionId
                );
              } else {
                modifierSelections.push({
                  modifierId,
                  modifierOptionIds: [selectedOptionId],
                });
              }
            }
          );
          selectedBundleItems.push({
            bundleItemId,
            bundleSectionId,
            modifierSelections,
          });
        });
      }

      const modifierSelections: ModifierSelectionRequest[] =
        Object.values(itemModifiers);

      let customModifiers: CustomModifiersSelectionRequest[] = [];
      if (item.specialRequest) {
        customModifiers = [
          {
            description: item.specialRequest,
            price: 0,
          },
        ];
      }

      return {
        menuId,
        sectionId: menuSectionId,
        itemId,
        quantity,
        sizeId,
        metadata: {
          modifierSelections,
          bundleItems: [...selectedBundleItems],
          customModifiersSelections: customModifiers,
        },
      };
    });
  }

  private getVenueName(): string {
    return this.store.selectSnapshot(VenueState.formattedVenueName);
  }

  private goToOrderSuccess(isDineIn = false): void {
    this.localStorage.setItem(
      LocaleStorageKeys.rsOrderSuccessShowContentKey,
      ''
    );

    const venueName = this.getVenueName();
    const url = `${venueName}/${isDineIn ? 'dine-in' : ''}/order-success`;

    this.ngZone.run(() => {
      this.dialog.closeAll();
      this.router.navigate([url]);
    });
  }

  private goToGiftSuccess(isDineIn = false): void {
    this.localStorage.setItem(
      LocaleStorageKeys.rsOrderSuccessShowContentKey,
      ''
    );

    const venueName = this.getVenueName();
    const url = `${venueName}/${isDineIn ? 'dine-in' : ''}/gift-card-success`;

    this.ngZone.run(() => {
      this.dialog.closeAll();
      this.router.navigate([url]);
    });
  }

  @Action(CancelOrder)
  cancelOrder({ patchState, dispatch }: StateContext<OrderStateModel>) {
    const orderDetails = this.store.selectSnapshot(OrderState.orderDetails)!;
    const orderMayBeCanceled =
      orderDetails.fullStatus === OrderItemStatus.Received;

    if (orderDetails.type === OrderType.DineIn) {
      return this.openCancelOrderMessagePopup(null, OrderType.DineIn);
    }

    if (!orderMayBeCanceled) {
      return this.openCancelOrderMessagePopup(orderDetails.fullStatus);
    }

    dispatch(new ShowSpinner());

    return this.orderService.cancelOrder(orderDetails.orderId).pipe(
      tap(() => {
        this.openCancelOrderMessagePopup(null);

        dispatch(new HideSpinner());
        patchState({
          orderDetails: {
            ...orderDetails,
            fullStatus: OrderItemStatus.Canceled,
          },
        });
      })
    );
  }

  @Action(CancelExistingOrder)
  cancelExistingOrder(
    { dispatch }: StateContext<OrderStateModel>,
    { orderId }: CancelExistingOrder
  ) {
    const venueName = this.store.selectSnapshot(VenueState.formattedVenueName);

    dispatch(new ShowSpinner());

    return this.orderService.cancelOrder(orderId).pipe(
      tap({
        next: () => {
          this.store.dispatch(new GetProfileOrders());
          this.ngZone.run(() => {
            this.dialog.closeAll();
            this.router.navigate([`${venueName}/profile/orders`]);
          });
          this.showSuccessMessage('NOTIFICATIONS.ORDER.successfully_canceled');
        },
        finalize: () => {
          dispatch(new HideSpinner());
        },
      })
    );
  }

  private openCancelOrderMessagePopup(
    status: OrderItemStatus | null,
    orderType?: OrderType
  ): void {
    let message: string;
    let translatedStatus = '';

    switch (true) {
      case orderType === OrderType.DineIn:
        message = "ORDER.dine-in orders can't be cancelled by consumers";
        break;

      case !!status:
        translatedStatus = this.translateService.instant(
          'ORDER.STATUS.' + status
        );
        message = "ORDER.Your order can't be cancelled";
        break;

      default:
        message = 'ORDER.Your order was canceled successfully';
        break;
    }

    this.store.dispatch(
      new OpenDialog(MessagePopupComponent, {
        id: MatDialogId.message,
        data: {
          title: this.translateService.instant('ORDER.CANCEL ORDER'),
          message: this.translateService.instant(
            message,
            status
              ? { status: translatedStatus.toLocaleLowerCase() }
              : undefined
          ),
        },
      })
    );
  }

  @Action(GetOrderPaymentDetails)
  getOrderPaymentDetails({
    patchState,
    dispatch,
  }: StateContext<OrderStateModel>) {
    const orderDetails = this.store.selectSnapshot(OrderState.orderDetails)!;

    if (!orderDetails.paymentIds?.length) {
      return;
    }

    dispatch(new ShowSpinner());

    const getOrderPaymentDetails = [];
    patchState({
      paymentDetails: [],
    });

    for (let i = 0; i < orderDetails.paymentIds.length; i++) {
      getOrderPaymentDetails.push(
        this.orderService
          .getOrderPaymentDetails(orderDetails.paymentIds[i])
          .pipe(catchError(() => of(null)))
      );
    }

    return combineLatest(getOrderPaymentDetails).pipe(
      map(paymentDetails => {
        return paymentDetails.filter(a => !!a) as OrderPaymentDetails[];
      }),
      tap(paymentDetails => {
        patchState({
          paymentDetails,
        });

        dispatch(new HideSpinner());
      }),
      catchError(() => of())
    );
  }

  @Action(ChangeOrderIsFavorite)
  ChangeOrderIsFavorite(
    { patchState }: StateContext<OrderStateModel>,
    { isFavorited }: ChangeOrderIsFavorite
  ) {
    const orderDetails = this.store.selectSnapshot(OrderState.orderDetails)!;

    return this.orderService
      .setOrderFavorite(orderDetails?.orderId!, isFavorited)
      .pipe(
        tap(() => {
          patchState({
            orderDetails: { ...orderDetails, isFavorited },
          });
        })
      );
  }

  @Action(GetItemsSuggestions)
  getItemsSuggestions({ patchState }: StateContext<OrderStateModel>) {
    const isLoggedIn: boolean = this.store.selectSnapshot(
      SessionState.isLoggedIn
    );

    if (!isLoggedIn) {
      return of();
    }
    const { id: venueId } = this.store.selectSnapshot(VenueState.venue);

    const endDate: string = getNow();
    const startDate: string = getNPeriodFromNow(
      DEFAULT_SUGGESTIONS_PERIOD_NUMBER,
      DEFAULT_SUGGESTION_PERIOD
    );
    const pageSize: number = DEFAULT_SUGGESTIONS_PAGE_SIZE;

    return this.orderService
      .getItemsSuggestion(venueId, startDate, endDate, pageSize)
      .pipe(
        tap((itemsSuggestionsResponse: ItemsSuggestionsResponse) => {
          const suggestionsPaginator: Paginator = {
            total: itemsSuggestionsResponse.total,
          };
          if (itemsSuggestionsResponse.previous) {
            suggestionsPaginator.previous = itemsSuggestionsResponse.previous;
          }
          if (itemsSuggestionsResponse.next) {
            suggestionsPaginator.next = itemsSuggestionsResponse.next;
          }
          patchState({
            rebuyItemsSuggestions: [
              ...(itemsSuggestionsResponse.results ?? []),
            ],
            // in case we need use pagination
            suggestionsPaginator,
          });
        })
      );
  }

  @Action(GetOrderDetails)
  fetchOrdertDetails(
    { patchState, dispatch }: StateContext<OrderStateModel>,
    { orderId, paymentIds }: GetOrderDetails
  ) {
    return this.orderService.getOrderDetails(orderId).pipe(
      tap(orderDetails => {
        orderDetails = { ...orderDetails, paymentIds };

        patchState({
          orderDetails,
        });
        const venueName = this.store.selectSnapshot(
          VenueState.formattedVenueName
        );
        this.ngZone.run(() => {
          this.dialog.closeAll();
          dispatch(new ClearHistory());
          this.router.navigate([`${venueName}/dine-in/order-success`]);
        });
      })
    );
  }

  @Action(GetUpdatedOrderDetails)
  getUpdatedOrderDetails({
    patchState,
    getState,
  }: StateContext<OrderStateModel>) {
    const orderDetails = getState().orderDetails!;

    return this.orderService.getOrderDetails(orderDetails?.orderId!).pipe(
      tap(
        ({
          fullStatus,
          updatedAt,
          scheduledFor,
          status,
          cancelledAt,
          statusHistory,
        }) => {
          patchState({
            orderDetails: {
              ...orderDetails,
              fullStatus,
              updatedAt,
              scheduledFor,
              status,
              cancelledAt,
              statusHistory,
            },
          });
        }
      )
    );
  }

  @Action(SaveUpdatedOrderDetails)
  saveUpdatedOrderDetails(
    { patchState, getState }: StateContext<OrderStateModel>,
    { orderDetails }: SaveUpdatedOrderDetails
  ) {
    const stateOrderDetails = getState().orderDetails!;

    patchState({
      orderDetails: {
        ...stateOrderDetails,
        fullStatus: orderDetails.fullStatus,
        updatedAt: orderDetails.updatedAt,
        scheduledFor: orderDetails.scheduledFor,
        status: orderDetails.status,
        cancelledAt: orderDetails.cancelledAt,
        statusHistory: orderDetails.statusHistory,
      },
    });
  }

  @Action(UpdateDineInOrder)
  updateDineInOrder(
    { patchState, dispatch, getState }: StateContext<OrderStateModel>,
    { orderId, order }: UpdateDineInOrder
  ) {
    const tips: Tips = this.store.selectSnapshot(CartState.tips);
    return this.orderService.updateDineInOrder(orderId, order).pipe(
      tap({
        next: (order: DineInOrderIDResponse) => {
          patchState({
            dineInOrderID: order.dineInOrderID,
            tips: tips,
          });
          if (!getState().isOpenTab) {
            dispatch([new ClearCart(), new ClearAddressInDeliveryData()]);
          }

          dispatch([
            new GetOrderDetails(order.dineInOrderID, order.paymentIds),
            new HideSpinner(),
          ]);
        },
      })
    );
  }

  private selectedPaymentMethod(): Card | GiftCard {
    const selectedPaymentCard: Card = this.store.selectSnapshot(
      CartState.selectedPaymentCard
    )!;
    const selectedGiftCard: GiftCard = this.store.selectSnapshot(
      CartState.selectedGiftCard
    )!;

    return !!selectedPaymentCard
      ? selectedPaymentCard
      : !!selectedGiftCard
      ? selectedGiftCard
      : ({} as Card | GiftCard);
  }

  @Action(SetGooglePay)
  setGooglePay(
    { patchState }: StateContext<OrderStateModel>,
    { googlePay }: SetGooglePay
  ) {
    patchState({ googlePay });
  }

  @Action(SetApplePay)
  setApplePay(
    { patchState }: StateContext<OrderStateModel>,
    { applePay }: SetApplePay
  ) {
    patchState({ applePay });
  }

  @Action(SetIsOpenTab)
  setIsOpenTab(
    { patchState }: StateContext<OrderStateModel>,
    { isOpenTab }: SetIsOpenTab
  ) {
    patchState({
      isOpenTab: isOpenTab,
    });
  }

  @Action(CloseOpenTab)
  closeOpenTab({ dispatch }: StateContext<OrderStateModel>) {
    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const venueId = this.store.selectSnapshot(VenueState.venueId);
    const orderId = this.store.selectSnapshot(DineInState.orderId);

    if (!orderId) {
      return;
    }

    dispatch(new ShowSpinner());
    return (
      isLoggedIn
        ? this.orderService.closeLogInTab(orderId, venueId)
        : this.orderService.closeGuestTab(orderId, venueId)
    ).pipe(
      tap({
        next: () => {
          dispatch([
            new HideSpinner(),
            new SetIsOpenTab(false),
            new ClearCart(),
          ]);
        },
        error: () => {
          dispatch(new HideSpinner());
        },
      })
    );
  }

  @Action(UpdateOpenTab)
  updateOpenTabForGuestUser({ dispatch }: StateContext<OrderStateModel>) {
    const orderId = this.store.selectSnapshot(DineInState.orderId);

    if (!orderId) {
      return;
    }

    const isLoggedIn = this.store.selectSnapshot(SessionState.isLoggedIn);
    const payload: OrderItemRequest = {} as OrderItemRequest;
    isLoggedIn
      ? (payload['orderItems'] = this.getOrderItemsRequestPayload())
      : (payload['items'] = this.getOrderItemsRequestPayload());
    dispatch(new ShowSpinner());

    return (
      isLoggedIn
        ? this.orderService.addToTabForLoggedInUser(orderId, payload)
        : this.orderService.updateDineInOrder(orderId, payload)
    ).pipe(
      tap({
        next: () => {
          dispatch([new HideSpinner()]);
        },
        error: () => {
          dispatch(new HideSpinner());
        },
      })
    );
  }
}
